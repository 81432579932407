<template>
  <div>
    <b-form>
      <div class="animated fadeIn">
        <b-row class="justify-content-center">
          <b-col md="8">
            <b-card no-body>
              <b-card-header>
                <i class="fa fa-edit"></i> Informações da revenda
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col>
                    <b-form-group label="Razão Social" label-for="razao_social">
                      <b-form-input
                        id="razao_social"
                        type="text"
                        :value="revenda.razaoSocial"
                        readonly
                      />
                    </b-form-group>
                    <b-form-group label="Endereço" label-for="endereco">
                      <b-form-input
                        id="endereco"
                        type="text"
                        :value="revenda.endereco"
                        readonly
                      />
                    </b-form-group>
                    <b-row>
                      <b-col md="5">
                        <b-form-group label="Cidade" label-for="cidade">
                          <b-form-input
                            id="cidade"
                            type="text"
                            :value="revenda.cidade"
                            readonly
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="Bairro" label-for="bairro">
                          <b-form-input
                            id="bairro"
                            type="text"
                            :value="revenda.bairro"
                            readonly
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="C.E.P" label-for="cep">
                          <b-form-input
                            id="cep"
                            type="text"
                            :value="revenda.cep"
                            readonly
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="8">
                        <b-form-group label="Site" label-for="site">
                          <b-form-input
                            id="site"
                            type="text"
                            :value="revenda.homePage"
                            readonly
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group
                          label="Telefone"
                          label-for="ddd_telefone_contato"
                        >
                          <b-form-input
                            id="ddd_telefone_contato"
                            type="text"
                            :value=" ((revenda.ddd || '').toString() +
                                       (revenda.fone || '').toString())
                                       | phone"
                            readonly
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
import Revenda from "../../../services/revenda";
import ImageUploader from "../../../components/ui/ImageUploader";
import { baseUrls } from "../../../settings";

export default {
  name: "DadosRevenda",
  components: {},
  data() {
    return {
      revenda: new Object(),
    };
  },
  computed: {},
  mounted() {
    this.loadDate();
  },
  methods: {
    loadDate() {
      Revenda.getRevendaDb1().then(
        response => {
          this.revenda = response.data.data;          
        }
      );
    }
  }
};
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
.label-margem {
  display:block
}
.label-margem span, #margem ~ p {
  color: red;
}
</style>
