<template>
  <div>
    <label :id="labelId" class="upload-image form-row" :label-for="id" :style="styleBg">
      <div class="upload-image-label upload-image-text align-self-center">
        <span>{{placeholder}}</span>
      </div>
      <input
        type="file"
        :id="id"
        :name="name"
        v-bind:value="value"
        @change="onFileChanged"
        v-on:input="$emit('input', $event.target.value)"
        accept="image/jpeg, image/png, image/gif"
        class="upload-image-input"
      />
    </label>
  </div>
</template>

<style scoped>
.upload-image-text {
  margin-left: auto;
  margin-right: auto;
}
.upload-image {
  width: 100%;
  height: auto;
  min-height: 20em;
  border: 1px solid black;
  border-style: dashed;
  border-radius: 7pt;
  background-repeat: no-repeat;
  background-position: center;
}

.upload-image-input {
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  width: 100%;
  height: 20em;
}

.upload-image-label {
  background: #ffffffc7;
  border-radius: 0.5em;
  padding: 0.7em;
  padding-left: 2em;
  padding-right: 2em;
  font-weight: bolder;
}

@media (max-width: 479px) {
  .upload-image {
    background-size: 100% auto;
  }
}

@media (min-width: 480px) {
  .upload-image {
    background-size: auto 100%;
  }
}
</style>

<script>
export default {
  name: "ImageUploader",
  props: {
    id: {
      type: String,
      default: "ImageUploader"
    },
    name: {
      type: String,
      default: "ImageUploader"
    },
    imgUrl: {
      type: String
    },
    placeholder: {
      type: String,
      default: "Escolha uma imagem..."
    },
    value: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    labelId() {
      return this.$props.id + "Label";
    },
    styleBg() {
      return 'background-image: url("' + this.$props.imgUrl + '")';
    }
  },
  data() {
    return {
      selectedFile: null
    };
  },
  methods: {
    onFileChanged(event) {
      let reader = new FileReader();
      reader.onload = event => {
        this.imgUrl = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.selectedFile = event.target.files[0];
      let formData = new FormData();
      formData.append("file", this.selectedFile, this.selectedFile.name);
      this.$emit("onUpload", formData);
    }
  }
};
</script>